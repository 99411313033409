import { BTable } from 'bootstrap-vue'
import { h } from '@vue/composition-api'
import config from '../autosuggestConfig'

const getValueOfCell = (tableColumn, value, data) => {
  if (typeof tableColumn?.getValue === 'function') {
    return tableColumn.getValue(value, data)
  }
  return value
}
const AutoSuggestCustomerSection = {
  name: 'auto-suggest-customer-section',
  components: {
    BTable,
  },
  props: {
    /** @type ResultSection */
    section: { type: Object, required: true },
    isTableHead: { type: Boolean, delete: () => false },
    currentIndex: { type: [Number, String], required: false, default: Infinity },
    renderSuggestion: { type: Function, required: false },
    normalizeItemFunction: { type: Function, required: true },
    componentAttrPrefix: { type: String, required: true },
    tableColumns: { type: Array, required: true },
    componentAttrIdAutosuggest: { type: String, required: true },
    searchQuery: { type: String, required: false, default: '' },
  },
  data() {
    return {
      /** @type Number */
      _currentIndex: this.currentIndex,
    }
  },
  computed: {
    /**
     * Suggestions from the section
     */
    list() {
      // eslint-disable-next-line prefer-const
      let { limit, data } = this.section
      if (data.length < limit) {
        limit = data.length
      }
      return data.slice(0, limit)
    },
  },
  methods: {

    makeStatusOrTrash(data, index) {
      switch (index) {
        case 0:

          if (!data.is_active) return this.icon.trashIcon
          if (data.status === 0) return this.icon.notVerifiedIcon
          if (data.status === 1) return this.icon.verifiedIcon
          if (data.status === 2) return this.icon.doNotRentIcon

          return ''

        case 1:

          if (data.default_contact && !data.default_contact.is_active) return this.icon.trashIcon

          return ''

        case 2:

          if (data.default_address && !data.default_address.is_active) return this.icon.trashIcon
          return ''

        default:
          return ''
      }
    },
    makeBold(str, query, data, _indexTb) {
      if (!str || !query) return
      // find the index of querystring in city name
      const index = str?.toString()?.toLowerCase().indexOf(query.toLowerCase())

      if (index > -1 && query.length) {
        // find the end position of substring in stripped city name
        // eslint-disable-next-line camelcase
        const endIndex = index + query.length - 1

        const first = str.substring(0, index)
        const middle = str.substring(index, endIndex + 1)
        const last = str.substring(endIndex + 1)

        switch (_indexTb) {
          case 0:

            // eslint-disable-next-line consistent-return
            if (!data.is_active) return h('span', { attrs: { style: 'color: #ADADAD' } }, [first, h('b', {}, [middle]), last])
            // eslint-disable-next-line consistent-return
            return str

          case 1:

            // eslint-disable-next-line consistent-return
            if (data.default_contact && !data.default_contact.is_active) return h('span', { attrs: { style: 'color: #ADADAD' } }, [first, h('b', {}, [middle]), last])
            // eslint-disable-next-line consistent-return
            return str

          case 2:

            // eslint-disable-next-line consistent-return
            if (data.default_address && !data.default_address.is_active) return h('span', { attrs: { style: 'color: #ADADAD' } }, [first, h('b', {}, [middle]), last])
            // eslint-disable-next-line consistent-return
            return str

          default:
            // eslint-disable-next-line consistent-return
            return h('span', { attrs: { style: '' } }, [first, h('b', {}, [middle]), last])
        }
      }
      // eslint-disable-next-line consistent-return
      return str
    },
    getItemIndex(i) {
      return this.section.start_index + i
    },
    getItemByIndex(i) {
      return this.section.data[i]
    },
    onMouseEnter(event) {
      const idx = parseInt(event.currentTarget.getAttribute('data-suggestion-index'))
      this._currentIndex = idx
      this.$emit('updateCurrentIndex', idx)
    },
    onMouseLeave() {
      this.$emit('updateCurrentIndex', null)
    },
  },
  // eslint-disable-next-line no-unused-vars
  render(h) {
    const { componentAttrPrefix } = this

    const beforeClassName = `${componentAttrPrefix}__results-before ${componentAttrPrefix}__results-before--${this.section.name}`

    return h(
      'table',
      {
        attrs: {
          role: 'listbox',
          'aria-labelledby': this.section.label && `${this.componentAttrIdAutosuggest}-${this.section.label}`,
          fields: this.table,
          show: this.section.data.length > 0,
        },
        class: `${this.section.ulClass} table b-table table-striped table-hover table-sm`,
      },
      [
        h('thead', {
          style: { display: this.isTableHead ? 'none' : 'revert' },
        }, [
          h('tr', {}, [
            this.tableColumns.map(column => h('th', {
              style: column.style,
            }, [
              column.label,
            ])),
          ]),
        ]),

        h('tbody', {}, [
          this.section.label && <li class={beforeClassName} id={`${this.componentAttrIdAutosuggest}-${this.section.label}`}>{this.section.label}</li> || '',

          this.list.map((val, key) => {
            if (!this.list.length) return
            const item = this.normalizeItemFunction(this.section.name, this.section.type, this.section.label, this.section.liClass, val)
            const itemIndex = this.getItemIndex(key)
            const isHighlighted = this._currentIndex === itemIndex || parseInt(this.currentIndex) === itemIndex
            const tableCol = []

            const data = { ...val }
            for (let i = 0; i < this.tableColumns.length; i++) {
              tableCol.push(getValueOfCell(this.tableColumns[i], data[this.tableColumns[i].key], data))
            }
            return h('tr', {
              style: { cursor: 'pointer' },
            }, [
              tableCol.map((sItem, index) => h('td',
                {
                  attrs: {
                    role: 'option',
                    'data-suggestion-index': itemIndex,
                    'data-section-name': item.name,
                    id: `${componentAttrPrefix}__results-item--${itemIndex + 1}`,
                    ...item.liAttributes,
                  },
                  key: index,
                  class: {
                    [`${componentAttrPrefix}__results-item--highlighted`]: isHighlighted,
                    [`${componentAttrPrefix}__results-item`]: true,
                    ...item.liClass,
                  },
                  on: {
                    mouseenter: this.onMouseEnter,
                    mouseleave: this.onMouseLeave,
                  },
                },
                [h('div', {
                  style: {
                    whiteSpace: 'nowrap', width: '200px', textOverflow: 'ellipsis', overflow: ' hidden',
                  },
                }, [this.makeStatusOrTrash(data, index), this.makeBold(sItem, this.searchQuery, data, index) ?? '—'])])),
            ])
          }),
        ]),
      ],
    )
  },

  setup() {
    const {
      trashIcon,
      notVerifiedIcon,
      doNotRentIcon,
      verifiedIcon,
    } = config()

    const icon = {
      trashIcon,
      notVerifiedIcon,
      doNotRentIcon,
      verifiedIcon,
    }
    return {
      icon,
    }
  },
}

export default AutoSuggestCustomerSection
