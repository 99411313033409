var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.componentAttrIdAutosuggest}},[_vm._t("before-input"),_c('div',{attrs:{"role":"combobox","aria-expanded":_vm.isOpen ? 'true' : 'false',"aria-haspopup":"listbox","aria-owns":(_vm.componentAttrIdAutosuggest + "-" + _vm.componentAttrPrefix + "__results")}},[_c('input',_vm._g(_vm._b({class:[_vm.isOpen ? (_vm.componentAttrPrefix + "__input--open") : '', _vm.internal_inputProps['class']],attrs:{"type":_vm.internal_inputProps.type,"autocomplete":_vm.internal_inputProps.autocomplete,"aria-autocomplete":"list","aria-activedescendant":_vm.isOpen && _vm.currentIndex !== null ? (_vm.componentAttrPrefix + "__results-item--" + _vm.currentIndex) : '',"aria-controls":(_vm.componentAttrIdAutosuggest + "-" + _vm.componentAttrPrefix + "__results"),"disabled":_vm.isEditable},domProps:{"value":_vm.internalValue},on:{"input":_vm.inputHandler}},'input',_vm.internal_inputProps,false),_vm.listeners))]),_vm._t("after-input"),_c('div',{class:_vm._componentAttrClassAutosuggestResultsContainer,attrs:{"id":(_vm.componentAttrIdAutosuggest + "-" + _vm.componentAttrPrefix + "__results")}},[(_vm.isOpen)?_c('div',{class:_vm._componentAttrClassAutosuggestResults,attrs:{"aria-labelledby":_vm.componentAttrIdAutosuggest}},[_vm._t("before-suggestions"),_vm._l((_vm.computedSections),function(cs,key){return _c(cs.type,_vm._b({key:_vm.getSectionRef(("" + (cs.name) + key)),ref:_vm.getSectionRef(("" + (cs.name) + key)),refInFor:true,tag:"component",attrs:{"section":cs},on:{"updateCurrentIndex":_vm.updateCurrentIndex},scopedSlots:_vm._u([{key:("before-section-" + (cs.name || cs.label)),fn:function(ref){
var section = ref.section;
var className = ref.className;
return [_vm._t(("before-section-" + (cs.name || cs.label)),null,{"section":section,"className":className})]}},{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
var _key = ref._key;
return [_vm._t("default",function(){return [_vm._v(" "+_vm._s(suggestion.item)+" ")]},{"suggestion":suggestion,"index":_key})]}},{key:("after-section-" + (cs.name || cs.label)),fn:function(ref){
var section = ref.section;
return [_vm._t(("after-section-" + (cs.name || cs.label)),null,{"section":section})]}},{key:"after-section",fn:function(ref){
var section = ref.section;
return [_vm._t("after-section",null,{"section":section})]}}],null,true)},'component',_vm.getSuggestProps(),false))}),_vm._t("after-suggestions")],2):_vm._e(),_vm._t("after-suggestions-container")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }